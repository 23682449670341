@tailwind base;
@tailwind components;
@tailwind utilities;

.grecaptcha-badge {
  visibility: hidden;
}

.chicory-button-container button {
  display: block;
  line-height: normal;
  border: 1px solid #bcbcbc;
  background: theme("colors.theme-primary-dark");
  color: #ffffff;
}

.slick-slider {
  .slick-track {
    @apply flex space-x-2;
  }

  &.slick-initialized .slick-slide > div {
    display: flex;
  }

  &.carousel-slider {
    .slick-dots {
      bottom: 4px;

      li {
        &.slick-active button:before {
          color: #279080;
          opacity: 1;
        }

        button:before {
          font-size: 0.75rem;
          opacity: 0.4;
        }
      }
    }

    > svg {
      stroke-width: 0.25rem;
      padding: 0.25rem;

      path {
        stroke-linecap: initial;
        opacity: 0.4;
      }
    }
  }
}

@media print {
  div.slick-slider {
    display: none !important;
  }
  ._yeti_main_container,
  #ezmob-wrapper {
    display: none !important;
  }
}
